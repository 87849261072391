import '@restapp/shared-polyfills'
import {initConfigs} from './initConfigs'

import {RUM, sessionStore, errorLogger} from '@eda-restapp/logger'
import {initRumScenarios} from './initRumScenarios'
import {startReactScan} from '@eda-restapp/react-scan'

function errorHandler(error: unknown) {
  // eslint-disable-next-line no-console
  console.error('Error in main.ts', error)
  errorLogger({error: error instanceof Error ? error : String(error)})
}

/**
 * TODO: после переезда на микрофронты у коры будет другая точка входа. Этот код необходимо будет выпилить
 */
async function microfrontendsRedirectExperiment() {
  try {
    const configs = await initConfigs()

    const microfrontendsConfig = configs?.restapp_microfrontends

    // Если для партнера включена новая версия, то мы редиректим на версию /v2 в рамках того же пути
    // Новый URL формируется путем добавления `/v2/` после домена:
    //   - Было: `vendor.eda.yandex/home`
    //   - Станет: `vendor.eda.yandex/v2/home`
    if (microfrontendsConfig?.enabled) {
      const origin = window.location.origin
      const currentPath = window.location.pathname

      window.location.replace(`${origin}/v2${currentPath}`)
      return
    }
  } catch (error) {
    errorLogger({
      level: 'error',
      error: error instanceof Error ? error : new Error('error initializing configs'),
      additional: {eventSlug: 'bootstrap:init_configs_error'}
    })
  }
}

async function main() {
  sessionStore.setData({
    version: VERSION,
    service: 'core'
  })

  RUM.sendTimeMark(RUM.Counter.Start)

  initRumScenarios()

  await microfrontendsRedirectExperiment().catch(errorHandler)
  await startReactScan().catch(errorHandler)
  await import(/* webpackChunkName: 'bootstrap' */ './bootstrap').catch(errorHandler)

  RUM.time(RUM.Counter.MainJS)
}

void main()
