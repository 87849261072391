import {getConfigsStore} from '@eda-restapp/configs'
import {getBrand, t} from '@eda-restapp/i18n'

import {getApplication} from '@restapp/shared/utils/Device/device'
import {errorLogger, eventLogger} from '@eda-restapp/logger'
import {retryWithBackoff} from '@eda-restapp/utils'

const INITIAL_RETRY_COUNT = 2
const ABORT_TIMEOUT = 5_000

const PERSIST_AUTH_DATA_KEY = 'persist:auth'

const safelyParseAuthDataValue = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (error) {
    errorLogger({
      error: error as Error,
      message: 'failed to parse auth data value',
      sourceType: 'configsStore'
    })

    return
  }
}

const getAuthHeaders = () => {
  try {
    const persistedAuthData = localStorage.getItem(PERSIST_AUTH_DATA_KEY)

    if (!persistedAuthData) {
      return
    }

    const parsedAuthData = JSON.parse(persistedAuthData)

    const parsedOauthToken = safelyParseAuthDataValue(parsedAuthData.oauthToken)
    const oauthTokenHeaderValue = parsedOauthToken ? `Bearer ${parsedOauthToken}` : undefined

    return {
      'X-Oauth': oauthTokenHeaderValue,
      'X-Partner-Id': safelyParseAuthDataValue(parsedAuthData.partnerId)
    } as Record<string, string>
  } catch (error) {
    errorLogger({
      error: error as Error,
      message: 'failed to parse persisted auth data',
      sourceType: 'configsStore'
    })

    return
  }
}

const configsStore = getConfigsStore()

configsStore.fetcher.setBaseUrl(window.location.origin)

// Передаем t для перевода конфигов которые все еще от нее зависят
configsStore.setTranslateFn(t)

export async function initConfigs() {
  try {
    const result = await retryWithBackoff(INITIAL_RETRY_COUNT, ABORT_TIMEOUT, async (signal) => {
      const fetchResult = await configsStore.fetcher.fetch({
        args: {
          application: getApplication(),
          domainHost: getBrand()
        },
        requestHeaders: getAuthHeaders(),
        signal
      })

      if (fetchResult instanceof Error) {
        throw fetchResult // Переводим ошибку в обработку retry
      }

      return fetchResult.data
    })

    return result
  } catch (error) {
    errorLogger({
      error: error as Error,
      message: 'initConfigs retries runout',
      sourceType: 'configsStore'
    })
    return null
  }
}

configsStore.fetcher.events.subscribe({
  name: 'fetchError',
  callback(payload) {
    if (payload.error.message.includes('AbortError')) {
      // fetch выбрасывает эту ошибку когда запрос был прерван через AbortSignal
      eventLogger({
        name: 'configsStore',
        value: 'configsStore:AbortError',
        additional: {
          requestBody: payload.request.body
        }
      })
      return
    }

    errorLogger({
      level: 'error',
      error: payload.error,
      sourceType: 'configsStore',
      message: `configsStore:fetchError:${payload.error.status}`,
      additional: {
        requestBody: payload.request.body,
        traceId: payload.error.traceId
      }
    })
  }
})

configsStore.fetcher.events.subscribe({
  name: 'fetchSuccess',
  callback(payload) {
    eventLogger({
      name: 'configsStore',
      value: 'configsStore:fetchSuccess',
      additional: {
        requestBody: payload.request.body
      }
    })
  }
})
